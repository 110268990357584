// Footer.tsx
import React from "react";
import { Box, Typography, Container, AppBar } from "@mui/material";
import { brandingConstants } from "../constants/brandingConstants";

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear(); // Dynamically get the current year

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: "#489D25",
        py: 2,
        position: "fixed",
        width: "100%",
        bottom: 0,
        mr: "2px",
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="subtitle2" color="white" align="center">
          Copyright © {currentYear} {brandingConstants.APP_NAME}
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;

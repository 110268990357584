function add(key: string, value: boolean | string | number): void {
  localStorage.setItem(key, value as string);
}

function update(key: string, value: boolean | string | number): void {
  if (localStorage.getItem(key)) {
    localStorage.setItem(key, value as string);
  } else {
    console.error(`Key '${key}' does not exist in localStorage.`);
  }
}

function remove(key: string): void {
  localStorage.removeItem(key);
}

function get(key: string): any {
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  } else {
    console.error(`Key '${key}' does not exist in localStorage.`);
    return null;
  }
}
export { add, update, remove, get };

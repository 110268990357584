import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import PageLoading from "./components/loading/PageLoading";
import Layout from "./layout/Layout";
import { routes } from "./routes/routes";
import { IRoutes } from "./types/interface/IRoutes";

function App() {
  return (
    <Suspense fallback={<PageLoading />}>
      {/* <ThemeProvider theme={theme}> */}
        <div className="app">
          <Routes>
            {routes.map((route: IRoutes, key: number) => (
              <Route
                path={route.path}
                key={key}
                element={
                  <Layout
                    children={route.component}
                    isProtected={route.isProtected}
                  />
                }
              />
            ))}
          </Routes>
        </div>
      {/* </ThemeProvider> */}
    </Suspense>
  );
}

export default App;

import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { AuthProvider } from "./contexts/authContext";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <AuthProvider>
      <BrowserRouter>
        <div>
          <App />
        </div>
      </BrowserRouter>
    </AuthProvider>
    <ToastContainer />
  </>

);

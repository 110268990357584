// Header.tsx
import AccountCircle from "@mui/icons-material/AccountCircle";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { apiEndpoints } from "../apis/apiEndpoints";
import { brandingConstants } from "../constants/brandingConstants";
import { useAuth } from "../contexts/authContext";
import DehazeIcon from "@mui/icons-material/Dehaze";
import useFetchImageUrl from "../hooks/useFetchImageUrl";

interface SidebarProps {
  toogleActive?: () => void;
}
const Header: React.FC<SidebarProps> = ({ toogleActive }) => {
  const { logout } = useAuth();
  const { authUser } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let userImg = useFetchImageUrl(authUser?.profilePicture as string);

  return (
    <AppBar
      color="inherit"
      sx={{
        backgroundColor: "#fff",
        boxShadow: "0px 4px 20px rgba(47, 143, 232, 0.07)",
        mb: "30px",
        position: "relative",
        padding: "10px",
      }}
      className="top-navbar-for-dark"
    >
      <Toolbar sx={{ justifyContent: "start" }}>
        {toogleActive && (
          <Tooltip title="Hide/Show" arrow>
            <IconButton
              sx={{ size: "small" }}
              edge="start"
              onClick={toogleActive}
            >
              <DehazeIcon />
            </IconButton>
          </Tooltip>
        )}

        <Box width={"100%"}>
          <img src="/images/app-logo.png" height={"60px"} />{" "}
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar
              src={userImg}
              alt="Adison Jeck"
              sx={{ width: 35, height: 35 }}
            />{" "}
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => (window.location.href = "/profile")}>
              Profile
            </MenuItem>
            <MenuItem onClick={() => logout(apiEndpoints.logout)}>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

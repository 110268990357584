import ClearIcon from "@mui/icons-material/Clear";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import React from "react";
import { useAuth } from "../../contexts/authContext";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { UserSidebarData } from "./UserSidebarData";

const SidebarNav = styled("nav")(({ theme }) => ({
  background: "#fff",
  boxShadow: "0px 4px 20px rgba(47, 143, 232, 0.07)",
  width: "300px",
  padding: "30px 10px",
  position: "absolute",
  top: "100px",
  bottom: "66px",
  left: 0,
  transition: "350ms",
  zIndex: "10",
  overflowY: "auto",
  marginTop: "-40px",
  marginRight: "20px",
}));

const SidebarWrap = styled("div")(({ theme }) => ({
  width: "100%",
}));

interface SidebarProps {
  toogleActive: () => void;
}

const SideBar: React.FC<SidebarProps> = ({ toogleActive }) => {
  const { authUser } = useAuth();

  return (
    <>
      <SidebarNav className="LeftSidebarNav">
        <SidebarWrap>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              onClick={toogleActive}
              size="small"
              sx={{
                background: "rgb(253, 237, 237)",
                display: { lg: "none" },
              }}
            >
              <ClearIcon />
            </IconButton>
          </Box>
          {authUser.role === "Admin" ? (
            <>
              {SidebarData.map((item, index) => {
                return <SubMenu item={item} />;
              })}
            </>
          ) : (
            <>
              {UserSidebarData.map((item, index) => {
                return <SubMenu item={item} />;
              })}
            </>
          )}
        </SidebarWrap>
      </SidebarNav>
    </>
  );
};

export default SideBar;

import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NavItem } from "./SidebarData";
import "./SubMenu.css";

const SidebarLabel = styled("span")(({ theme }) => ({
  position: "relative",
  top: "-3px",
}));

interface SubMenuProps {
  item: NavItem;
}

const SubMenu: React.FC<SubMenuProps> = ({ item }) => {
  const [subnav, setSubnav] = useState<boolean>(true);
  const showSubnav = () => setSubnav(!subnav);
  const [currentPath, setCurrentPath] = useState<string>("");

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <>
      <Link
        to={item.path}
        onClick={item.subNav ? showSubnav : undefined}
        className={`${"sidebarLink"} ${
          currentPath === item.path && "sidebarLinkActive"
        }`}
      >
        <div>
          <item.icon />
          <SidebarLabel sx={{ ml: "7px" }}>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && (subnav ? <item.iconOpened /> : <item.iconClosed />)}
        </div>
      </Link>
      {subnav &&
        item.subNav?.map((subItem, index) => (
          <Link
            to={subItem.path}
            key={index}
            className={`${"sidebarLink2"} ${
              subItem.path === window.location.pathname && "sidebarLinkActive"
            }`}
          >
            {subItem.title}
          </Link>
        ))}
    </>
  );
};

export default SubMenu;

import GridViewIcon from "@mui/icons-material/GridView";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ElementType } from "react";

export interface SubNavItem {
  title: string;
  path: string;
}

export interface NavItem {
  title: string;
  path: string;
  icon: ElementType;
  iconClosed?: any | ElementType;
  iconOpened?: any | ElementType;
  subNav?: SubNavItem[];
}

export const UserSidebarData: NavItem[] = [
  {
    title: "Home",
    path: "/",
    icon: GridViewIcon,
    iconClosed: KeyboardArrowRightIcon,
    iconOpened: KeyboardArrowDownIcon,
  },
];

import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import GridViewIcon from "@mui/icons-material/GridView";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { ElementType } from "react";

export interface SubNavItem {
  title: string;
  path: string;
}

export interface NavItem {
  title: string;
  path: string;
  icon: ElementType;
  iconClosed?: any | ElementType;
  iconOpened?: any | ElementType;
  subNav?: SubNavItem[];
}

export const SidebarData: NavItem[] = [
  {
    title: "Home",
    path: "/",
    icon: GridViewIcon,
    iconClosed: KeyboardArrowRightIcon,
    iconOpened: KeyboardArrowDownIcon,
  },
  {
    title: "Submission",
    path: "/submissions",
    icon: ConnectWithoutContactIcon,
    iconClosed: KeyboardArrowRightIcon,
    iconOpened: KeyboardArrowDownIcon,
  },
  {
    title: "View User",
    path: "",
    icon: PeopleAltIcon,
    iconClosed: KeyboardArrowRightIcon,
    iconOpened: KeyboardArrowDownIcon,
    subNav: [
      {
        title: "Users",
        path: "/users/",
      },
      {
        title: "Agents",
        path: "/agents/",
      },
    ],
  },
];

export const apiEndpoints = {
  // authentication
  facebookLogin: "/account/facebook-login",
  googleLogin: "/account/google-login",
  logout: "/account/logout",

  // me
  me: "/account/me",

  // document
  getAllDocument: "/document/getAll",
  getDocumentByCreator: "/document/byCreator",
  getByAttached: "/document/byAttached",
  addNewDocument: "/document/addNew",
  updateDocument: "/document/update",
  deleteDocumentById: "/delete/document",
  deleteDocumentByCreator: "/document/delete/byCreator",
  deleteDocumentByAttached: "/document/delete/byAttached",
  downloadDocument: "/api/document/download",

  // user
  getAllUsers: "/user/getAll",
  getUserById: "/user/byId",
  getUserByEmail: "/user/byEmail",
  addNewUser: "/user/addNew",
  updateUser: "/user/update",
  deleteUserById: "/user/delete",
  activateUser: "/user/activate",
  deactivateUser: "/user/deactivate",
  undoDeleteUser: "/user/undoDelete",
  getUserWithDetails: "/user/withDetails",
  getUserWithFilter: "/user/withFilter",
  getUserWithPagination: "/user/getUserPagination",
  filterUser: "/user/withFilter",
  userDocuments: "/user/documents",

  // user details
  getAllUserDetails: "/userDetail/getAll",
  getUserDetailById: "/userDetail",
  getUserDetailByUserId: "/userDetail/byUserId",
  addNewUserDetail: "/userDetail/addNew",
  updateUserDetailById: "/userDetail/update",
  updateUserDetailByUserId: "/userDetail/byUserId",
  deleteUserDetailById: "/userDetail/delete",
  deleteUserDetailByUserId: "/userDetail/delete/byUserId",

  // user submission
  getAllUserSubmissions: "/userSubmission/getAll",
  getAllUserSubmissionsPagination:
    "/userSubmission/getUserSubmissionPagination",
  getUserSubmissionById: "/userSubmission",
  getUserSubmissionByUserId: "/userSubmission/byUserId",
  addNewUserSubmission: "/userSubmission/addNew",
  updateUserSubmissionById: "/userSubmission/update",
  updateUserSubmissionByUserId: "/userSubmission/update/byUserId",
  deleteUserSubmissionById: "/userSubmission/delete",
  deleteUserSubmissionByUserId: "/userSubmission/delete/byUserId",
  userSubmissionDocuments: "/userSubmission/getSubmissionDocument",

  // CURRENT USER
  getCurrentUserSubmission: "/currentUser/submissions",
  getCurrentDetails: "/currentUser/details",
  getCurrentUser: "/currentUser/user",
  currentUserDocuments: "/currentUser/documents",
  currentUserattachFile: "/currentUser/attachFile",
  currentUserDownloadDocument: "/api/currentUser/download",

  //DASHBOARD COUNT
  getDashboardCount: "/api/dashboard/GetDashboardTotalCount",
};

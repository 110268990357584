import { Box } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageLoading from "../components/loading/PageLoading";
import { useAuth } from "../contexts/authContext";
import { add, get } from "../helper/localStore";
import { UserRole } from "../types/enums/UserRole";
import Footer from "./Footer";
import Header from "./Header";
import SideBar from "./sidebar/SideBar";
interface Props {
  children: ReactNode;
  isProtected: boolean;
}
const Layout: React.FC<Props> = ({ children, isProtected }) => {
  const { authUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState(true);

  const toogleActive = () => {
    // setActive(!active);
    add("sidebar", !active);
    setActive(get("sidebar") as boolean);
  };

  useEffect(() => {
    if (authUser.roleId === UserRole.User && isProtected) {
      navigate("/");
    }
    if (authUser.isLoaded) {
      // if (location.pathname !== "/login" && !authUser.isValidUser) {
      //   navigate("/login");
      // }
      if (isProtected) {
        if (authUser.roleId <= 0 && location.pathname !== "/login") {
          navigate("/login");
        }
      }
      if (authUser.roleId > 0 && !authUser.isValidUser) {
        navigate("/unauthorized");
      }
      if (
        authUser.roleId > 0 &&
        location.pathname === "/login" &&
        authUser.isValidUser
      ) {
        navigate("/");
      }
    }
  }, [authUser.isLoaded]);

  return (
    <>
      {authUser.isLoaded ? (
        <>
          {authUser.roleId === UserRole.User ? (
            <div>
              <div className="children-wrapper">
                {isProtected && <Header toogleActive={toogleActive} />}
                {active ? (
                  <>
                    <Box display={"flex"}>
                      {isProtected && <SideBar toogleActive={toogleActive} />}
                      {/* <Suspense fallback={<DataLoading />}> */}
                      <Box
                        width={"100%"}
                        marginLeft={isProtected ? "340px" : ""}
                        marginRight={isProtected ? "20px" : ""}
                        height={"73vh"}
                        sx={{ overflowY: "auto" }}
                      >
                        {" "}
                        {children}
                      </Box>
                      {/* </Suspense> */}
                      {isProtected && <Footer />}
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      paddingX="50px"
                      marginY="20px"
                      sx={{
                        overflowY: "auto",
                        position: "absolute",
                        bottom: "54px",
                        top: "90px",
                        right: "0",
                        left: "0",
                      }}
                    >
                      {" "}
                      {children}
                    </Box>
                    {isProtected && <Footer />}
                  </>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className="children-wrapper">
                {isProtected && <Header toogleActive={toogleActive} />}
                {active ? (
                  <>
                    <Box display={"flex"}>
                      {isProtected && <SideBar toogleActive={toogleActive} />}
                      {/* <Suspense fallback={<DataLoading />}> */}
                      <Box
                        width={"100%"}
                        marginLeft={isProtected ? "340px" : ""}
                        marginRight={isProtected ? "20px" : ""}
                        height={"73vh"}
                        sx={{ overflowY: "auto" }}
                      >
                        {" "}
                        {children}
                      </Box>
                      {/* </Suspense> */}
                      {isProtected && <Footer />}
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      paddingX="50px"
                      marginY="20px"
                      sx={{
                        overflowY: "auto",
                        position: "absolute",
                        bottom: "54px",
                        top: "90px",
                        right: "0",
                        left: "0",
                      }}
                    >
                      {" "}
                      {children}
                    </Box>
                    {isProtected && <Footer />}
                  </>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <PageLoading />
      )}
    </>
  );
};

export default Layout;

import { lazy } from "react";
import { IRoutes } from "../types/interface/IRoutes";

const Home = lazy(() => import("../views/Home"));

//
const Users = lazy(() => import("../views/users/Users"));
const Agents = lazy(() => import("../views/users/Agents"));
const AddNewUser = lazy(() => import("../views/users/AddNewUser"));
const AddAgent = lazy(() => import("../views/users/AddAgent"));
const UpdateUser = lazy(() => import("../views/users/UpdateUser"));
const ViewUser = lazy(() => import("../views/users/ViewUser"));
const Profile = lazy(() => import("../views/users/Profile"));

// submission
const Submissions = lazy(() => import("../views/submissions/Submissions"));
const ViewSubmission = lazy(
  () => import("../views/submissions/ViewSubmission")
);
const UpdateSubmission = lazy(
  () => import("../views/submissions/UpdateSubmission")
);

//
const Login = lazy(() => import("../views/Login"));
const NotFound = lazy(() => import("../views/NotFound"));
const Unauthorized = lazy(() => import("../views/Unauthorized"));
export const routes: IRoutes[] = [
  {
    path: "/",
    component: <Home />,
    isProtected: true,
  },
  {
    path: "/users",
    component: <Users />,
    isProtected: true,
  },
  {
    path: "/agents",
    component: <Agents />,
    isProtected: true,
  },
  {
    path: "/users/:userId",
    component: <ViewUser />,
    isProtected: true,
  },
  {
    path: "/users/addAgent",
    component: <AddAgent />,
    isProtected: true,
  },
  {
    path: "/users/addNew",
    component: <AddNewUser />,
    isProtected: true,
  },
  {
    path: "/users/update/:userId",
    component: <UpdateUser />,
    isProtected: true,
  },
  {
    path: "/submissions",
    component: <Submissions />,
    isProtected: true,
  },
  {
    path: "/submissions/update/:submissionId",
    component: <UpdateSubmission />,
    isProtected: true,
  },
  {
    path: "/submissions/:submissionId",
    component: <ViewSubmission />,
    isProtected: true,
  },
  {
    path: "/profile",
    component: <Profile />,
    isProtected: true,
  },
  {
    path: "/login",
    component: <Login />,
    isProtected: false,
  },
  {
    path: "/unauthorized",
    component: <Unauthorized />,
    isProtected: false,
  },
  {
    path: "/not-found",
    component: <NotFound />,
    isProtected: false,
  },
  {
    path: "/*",
    component: <NotFound />,
    isProtected: false,
  },
];

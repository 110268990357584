import React from "react";
import Lottie from "lottie-react";
import * as pageLoading from "../../assets/lottieFiles/pageLoading.json";
import { Box } from "@mui/material";

const PageLoading = () => {
  return (
    <Box
      height="99vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Lottie
        animationData={pageLoading}
        style={{
          height: "160px",
        }}
      />
    </Box>
  );
};

export default PageLoading;

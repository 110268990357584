import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { IAuthenticatedUser } from "../types/interface/IAuthenticatedUser";
import axios from "axios";
import { apiEndpoints } from "../apis/apiEndpoints";
import apiService from "../apis/apiService";

interface AuthContextProps {
  children: ReactNode;
}

interface AuthContextType {
  authUser: IAuthenticatedUser;
  isAuthenticated: boolean;
  login: (url: string) => void;
  logout: (url: string) => void;
}
const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<AuthContextProps> = ({ children }) => {
  const [authUser, setAuthUser] = useState<IAuthenticatedUser>({
    userId: 0,
    firstName: "",
    lastName: "",
    email: "",
    profilePicture: "",
    roleId: 0,
    role: "",
    isLoaded: false,
    isValidUser: false,
  });
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const login = async (url: string) => {
    window.location.href = "/api" + url;
  };

  const logout = async (url: string) => {
    window.location.href = "/api" + url;
  };

  useEffect(() => {
    try {
      const me = apiService.get(apiEndpoints.me);
      me.then((res) => {
        setAuthUser(res.data);
        setAuthUser((prev) => ({ ...prev, isLoaded: true }));
        setIsAuthenticated(true);
      });
    } catch (error) {}
  }, []);
  return (
    <AuthContext.Provider value={{ authUser, isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};

export { AuthProvider, useAuth };
